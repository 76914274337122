<template>
    <component :is="tag" v-bind="$attrs">
        <img style="border-radius: 12px;" class="w-100 no-border border-0" :src="offer.imageUri">

        <div class="p-x-24 p-y-24 p-x-small-0">
            <p class="font f-s-18 f-w-700 l-h-28 m-0">
                {{ offer.name }}
            </p>
            <div class="m-y-4">
                <span class="d-none d-md-inline-block d-lg-inline-block d-xl-inline-block font f-s-14 f-w-500 l-h-16 l-t-s-3">by</span>
                <span class="font f-s-14 f-w-700 l-h-16 l-t-s-3">{{ offer.brandName }}</span>
            </div>
            <!-- <div class="p-t-4">
                <span>{research.type}</span>
            </div> -->
        </div>
    </component>
</template>

<script>
export default {
    props: {
        tag: {
            type: String,
            default: 'a',
        },
        offer: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>
